import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "../components/Header/index";
import Galeria from "../components/Galeria";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div>
      <Header />
      <div className="container">
        <Galeria />
      </div>
      <Footer />
    </div>
  );
}
