import React from "react";
import Efeito from "./efect";
import "./style.css";
//import Surfer from "../Content/img/surfista.png";
import Foto1 from "./img/foto-01.png";
import Foto2 from "./img/foto-02.png";
import Foto3 from "./img/foto-03.png";
import Foto4 from "./img/foto-04.png";
import Foto5 from "./img/foto-05.png";
import Foto6 from "./img/foto-06.png";

//import Efect from "../Efects/index.js";
//import Blog from "../Blog"
//import { Link } from "gatsby";

const Galeria = () => (
  <>
    <Efeito />
    <div id="container-destaques" className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto1}></img>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto2}></img>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto3}></img>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto4}></img>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto5}></img>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <img className="img-thumbnail works" src={Foto6}></img>
        </div>
      </div>
    </div>
  </>
);

export default Galeria;
